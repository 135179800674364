import axios from '@/config/httpConfig'


//获取所有服务报表模板
export function getAllTemplate(params) {
  return axios.post('reporttemplate/getAllTemplate', params)
}
// 获获取选项列表值
export function GetViewGetSelectValue(data) {
  return axios.post('/view/getSelectValue', data)
}

// 获取币种信息
export function GetCurrencyInfo(data) {
  return axios.post('/currency/getCurrencyInfo', data)
}

//新建服务报表模板
export function createTemplate(params) {
  return axios.post('reporttemplate/createTemplate', params)
}

//删除服务报表模板
export function delTemplate(params) {
  return axios.post('reporttemplate/delTemplate', params)
}

//获取对象相关列表
export function getRelationList(params) {
  return axios.post('reporttemplate/getRelationList', params)
}

//保存服务报表模板
export function saveTemplate(params) {
  return axios.post('reporttemplate/saveTemplate', params)
}

//获取服务报表模板信息
export function getTemplateById(params) {
  return axios.post('reporttemplate/getTemplateById', params)
}

//更新服务报告模板启禁用状态
export function updateStatus(params) {
  return axios.post('reporttemplate/updateStatus', params)
}

//获取对象字段列表
export function getFieldList(params) {
  return axios.post('reporttemplate/getFieldList', params)
}

// 获取查找信息
export function getLookupInfo(data) {
  return axios.post('/lookup/getLookupInfo', data)
}

// 根据名称搜索对象记录接口--查找字段自动搜索
export function queryMoreDataByName(data) {
  return axios.post('objectdetail/queryMoreDataByName', data)
}
// 获取记录类型
export function getRecordType(data) {
  return axios.post('/batch/getRecordType', data)
}