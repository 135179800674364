<template>
  <div class="template">
    <!-- 标题 -->
    <!-- 服务报告模版 label.setup.service.template-->
    <div class="title">{{ $t("label.setup.service.template") }}</div>
    <!-- 解释 -->
    <!-- 点击“新建”，以根据标准模版或自己其中一个模版创建服务报表模版。可以使用时，将其启用。lable.New.tips -->
    <div class="intro">
      {{ $t("lable.New.tips") }}
    </div>
    <!-- 列表 -->
    <div class="list">
      <div class="firstIntro">
        <div class="title" style="font-size: 16px">
          {{ $t("label.setup.service.template") }}
        </div>
        <!-- 按钮 -->
        <!-- 新建 label.import.page1.newcreate-->
        <button class="create" @click="createNewTemplate">
          {{ $t("label.import.page1.newcreate") }}
        </button>
        <div></div>
      </div>
      <!-- 数据 -->
      <div class="header">
        <el-table
          :empty-text="$t('label.weixin.nomore')"
          ref="multipleTable"
          max-height="400"
          :data="templateList"
          :header-cell-style="{ background: '#DCDCDC', color: 'black' }"
        >
          <el-table-column
            :prop="item.name"
            v-for="(item, index) in headList"
            :key="index"
            :label="item.fieldLabel"
            show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <!-- 编辑 label.emailtocloudcc.edit-->
              <!-- 删除 label.emailobject.delete-->
              <!-- 禁用 label.emailsync.status.disabel-->
              <!-- 启用 label.emailtocloudcc.button.enable-->
              <div class="opreator" v-if="item.id == '001'">
                <span @click="editTemplate(scope.row)">{{
                  $t("label.emailtocloudcc.edit")
                }}</span>
                <span>|</span>
                <span
                  class="delete"
                  v-if="scope.row.id != 'servicereportdefault'"
                  @click="DeleteConfirmation(scope.row)"
                  >{{ $t("label.emailobject.delete") }}</span
                >
                <span v-if="scope.row.id != 'servicereportdefault'">|</span>
                <span
                  v-if="scope.row.isenable == '1'"
                  @click="updataTemplate(scope.row)"
                  >{{ $t("label.emailsync.status.disabel") }}</span
                >
                <span v-else @click="updataTemplate(scope.row)">{{
                  $t("label.emailtocloudcc.button.enable")
                }}</span>
              </div>
              <div v-else-if="item.id == '005'">
                <el-checkbox
                  v-model="isEnableStatus"
                  v-if="scope.row.isenable == '1'"
                  disabled
                ></el-checkbox>
                <el-checkbox
                  v-model="DisableStatus"
                  v-if="scope.row.isenable == '0'"
                  disabled
                ></el-checkbox>
              </div>
              <div v-else>{{ scope.row[item.name] }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 提示 label.popup.infoz-->
    <el-dialog :title="$t('label.popup.infoz')" :visible.sync="deleteStatus" width="30%">
      <!-- 确定删除{{ this.currentTelmplateName }}服务报告模板吗 -->
      <span>{{
        $t("lable.want.to.delete.tem", {
          currentTelmplateName: currentTelmplateName,
        })
      }}</span>
      <span slot="footer" class="dialog-footer">
        <!-- 取消 label.emailsync.button.cancel-->
        <!-- 确定 label.tabpage.ok-->
        <el-button @click="deleteStatus = false">{{
          $t("label.emailsync.button.cancel")
        }}</el-button>
        <el-button type="primary" @click="deleteTemplate">{{
          $t("label.tabpage.ok")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>




<script>
import * as ReportTemplate from "./api.js";
export default {
  data() {
    return {
      isEnableStatus: true,
      DisableStatus: false,
      templateList: [],
      currentTelmplateName: "",
      currentTelmplateId: "",
      deleteStatus: false,
      headList: [
        // 操作 label.import.index.operation
        // 名称 label.emailtocloudcc.name
        // 创建人 label.createname
        // 最后修改人 label.lastmodifyby
        // 启用 label.emailtocloudcc.button.enable
        {
          id: "001",
          fieldLabel: this.$i18n.t("label.import.index.operation"),
          name: "opreator",
        },
        {
          id: "002",
          fieldLabel: this.$i18n.t("label.emailtocloudcc.name"),
          name: "name",
        },
        {
          id: "003",
          fieldLabel: this.$i18n.t("label.createname"),
          name: "createname",
        },
        {
          id: "004",
          fieldLabel: this.$i18n.t("label.lastmodifyby"),
          name: "lastmodifyname",
        },
        {
          id: "005",
          fieldLabel: this.$i18n.t("label.emailtocloudcc.button.enable"),
          name: "open",
        },
      ],
    };
  },
  methods: {
    //获取单个服务报告模板详情
    editTemplate(val) {
      this.$router.push({
        path: "editTemplate",
        query: {
          Tid: val.id,
          Tname: val.name,
        },
      });
    },
    //获取全部服务报告模板
    async getAllReportTemplate() {
      let resultData = await ReportTemplate.getAllTemplate({ id: "" });
      resultData.data.forEach((item) => {
        if (item.createname) {
          item.createname = item.createname + "，" + item.createdate;
        }
        if (item.lastmodifyname) {
          item.lastmodifyname =
            item.lastmodifyname + "，" + item.lastmodifydate;
        }
      });
      this.templateList = resultData.data;
    },
    createNewTemplate() {
      this.$router.push({
        path: "newTemplate",
        query: {
          querytemplateList: JSON.stringify(this.templateList),
        },
      });
    },
    //更新启禁用状态
    async updataTemplate(data) {
      let status;
      let statusmsg;
      // 启用成功 label.group.isusing.success
      // 禁用成功 lable.Disabled.successfully
      if (data.isenable == "0") {
        status = "1";
        statusmsg = this.$i18n.t("label.group.isusing.success");
      }
      if (data.isenable == "1") {
        status = "0";
        statusmsg = this.$i18n.t("lable.Disabled.successfully");
      }
      let resultData = await ReportTemplate.updateStatus({
        id: data.id,
        isenable: status,
      });
      if (resultData.result) {
        this.templateList.map((item) => {
          if (item.id == data.id) {
            item.isenable = status;
          }
        });
        this.$message({
          message: statusmsg,
          type: "success",
        });
      }
    },
    //删除确认弹窗
    DeleteConfirmation(data) {
      this.currentTelmplateName = data.name;
      this.currentTelmplateId = data.id;
      this.deleteStatus = true;
    },
    //删除模板
    async deleteTemplate() {
      let resultData = await ReportTemplate.delTemplate({
        id: this.currentTelmplateId,
      });
      if (resultData.result) {
        this.deleteStatus = false;
        this.templateList.map((item, index) => {
          if (item.id == this.currentTelmplateId) {
            this.templateList.splice(index, 1);
          }
        });
      }
    },
  },
  mounted() {
    this.getAllReportTemplate();
  },
  created() {},
  components: {},
};
</script>


<style lang="scss" scoped>
* {
  margin: 0;
}
.template {
  padding-top: 18px;
  padding-left: 10px;
  padding-right: 10px;
}
.title {
  height: 26px;
  font-size: 20px;
  font-weight: bold;
  color: #333333;
  line-height: 26px;
}
.intro {
  margin-top: 20px;
  height: 19px;
  font-size: 14px;
  color: #333333;
  line-height: 19px;
}
.list {
  margin-top: 20px;
  width: 100%;
  background: #f5f5f5;
  border-radius: 3px;
  border: 1px solid #dcdcdc;
  padding-top: 18px;
  padding-left: 10px;
  padding-right: 10px;
}
.firstIntro {
  display: flex;
  justify-content: space-between;
}
.create {
  padding: 3px 16px;
  height: 30px;
  font-size: 14px;
  background: #ffffff;
  border-radius: 3px;
  border: 1px solid #dcdcdc;
}
.create:focus {
  outline: none;
}
.header {
  width: 100%;
  margin-top: 20px;
  padding-bottom: 10px;
}
::v-deep .el-table .cell div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.opreator {
  cursor: pointer;
  color: #006dcc;
  font-size: 14px;
}
.opreator span {
  margin-left: 3px;
}
.delete {
  cursor: pointer;
}
// .delete::after {
//   content: "|";
//   color: #006dcc;
// }
</style>
